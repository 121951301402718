/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const plugin = {
  id: "corsair",
  defaults: {
    width: 1,
    color: "#FF4949",
    dash: [3, 3],
  },
  afterInit: (chart: any, args: any, opts: any) => {
    chart.corsair = {
      x: 0,
      y: 0,
      draw: false,
    };
  },
  afterEvent: (chart: any, args: any) => {
    const { inChartArea } = args;
    const { type, x, y } = args.event;

    chart.corsair = { x, y, draw: inChartArea };
    chart.draw();
  },
  beforeDatasetsDraw: (chart: any, args: any, opts: any) => {
    const { ctx } = chart;
    const { top, bottom, left, right } = chart.chartArea;
    const { x, y, draw } = chart.corsair || { x: 0, y: 0, draw: false };
    if (!draw) return;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = opts.width;
    ctx.strokeStyle = opts.color;
    ctx.setLineDash(opts.dash);
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    // ctx.moveTo(left, y)
    // ctx.lineTo(right, y)
    ctx.stroke();

    ctx.restore();
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    corsair: {
      color: "black",
    },
    datalabels: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
  hover: {
    mode: "index" as const,
    intersect: false,
  },
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  cubicInterpolationMode: "monotone",
  scales: {
    x: {
      stacked: true,
    },
    y: {
      ticks: {
        callback: (value: number | string) => {
          return value + "%";
        },
      },
    },
  },
  animation: {
    duration: 1000,
    easing: "easeOutCubic",
  } as const,
};

export type LineDataSetType = {
  label: string;
  data: Number[];
  borderColor: string;
  backgroundColor: string;
};

export type LineData = {
  labels: string[];
  datasets: LineDataSetType[];
};

type LineChartProps = {
  data: LineData;
};

const LineChartJS = (props: LineChartProps) => {
  const { data } = props;
  return <Line options={options} plugins={[plugin]} data={data} />;
};
export default React.memo(LineChartJS);
