const filterParams = { buttons: ["apply", "reset"] };

const dateFilterParams = Object.assign(filterParams, {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: any) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return 0;

    const dateParts = dateAsString.split("T")[0].split("-");
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2]);
    const cellDate = new Date(year, month, day);

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
});

const _monthToNum = (date: string): number | null => {
  if (date === undefined || date === null || date.length !== 10) return null;

  var yearNumber = parseInt(date.substring(6, 10));
  var monthNumber = parseInt(date.substring(3, 5));
  var dayNumber = parseInt(date.substring(0, 2));

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  // 29/08/2004 => 20040829
  return result;
};

const dateComparator = (date1: string, date2: string) => {
  var date1Number = _monthToNum(
    new Date(date1)
      .toLocaleString("en-GB", { timeZone: "Asia/Kolkata" })
      .split(",")[0],
  );
  var date2Number = _monthToNum(
    new Date(date2)
      .toLocaleString("en-GB", { timeZone: "Asia/Kolkata" })
      .split(",")[0],
  );

  if (date1Number === null && date2Number === null) return 0;
  if (date1Number === null) return -1;
  if (date2Number === null) return 1;

  return date1Number - date2Number;
};

const amountComparator = (number1: string, number2: string) => {
  var val1Number = parseFloat(number1.replace("₹", "").replace(",", ""));
  var val2Number = parseFloat(number2.replace("₹", "").replace(",", ""));

  if (val1Number === null && val2Number === null) return 0;
  if (val1Number === null) return -1;
  if (val2Number === null) return 1;

  return val1Number - val2Number;
};

const percentageComparator = (number1: string, number2: string) => {
  var val1Number = parseFloat(number1.replace("%", ""));
  var val2Number = parseFloat(number2.replace("%", ""));

  if (val1Number === null && val2Number === null) return 0;
  if (val1Number === null) return -1;
  if (val2Number === null) return 1;

  return val1Number - val2Number;
};

const daysComparator = (number1: string, number2: string) => {
  var val1Number = parseFloat(number1.replace("days", "").replace("day", ""));
  var val2Number = parseFloat(number2.replace("days", "").replace("day", ""));

  if (val1Number === null && val2Number === null) return 0;
  if (val1Number === null) return -1;
  if (val2Number === null) return 1;

  return val1Number - val2Number;
};

export {
  filterParams,
  dateFilterParams,
  dateComparator,
  amountComparator,
  percentageComparator,
  daysComparator,
};
