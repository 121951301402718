import React from "react";

export const Loader = () => (
  <div className="loader">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

export const Skeleton = () => {
  <div
    className="skeleton-loader"
    style={{ height: "40px", width: "100%" }}
  ></div>;
};
const barHeights = [
  20, 20, 30, 40, 40, 50, 50, 40, 30, 30, 50, 50, 60, 60, 70, 70, 50, 90, 70,
  70, 50, 90, 60, 30, 70, 50, 90, 30, 60, 90, 70, 50, 30, 60, 70, 50, 90, 50,
  90, 60, 30, 70, 50, 90, 30, 60, 90, 70, 50, 30, 60, 70, 50, 90, 90, 70, 80,
  90, 60, 30, 70, 50, 90, 30, 60, 90, 70, 50, 30, 60, 70, 50, 90, 90, 70, 80,
];

export const SkeletonBarLoader = () => (
  <div
    className="placeholder-glow"
    style={{
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      height: "100%",
    }}
  >
    {barHeights.map((height, index) => (
      <span
        key={index}
        className="placeholder"
        style={{
          height: `${height}%`,
          width: "5px",
          backgroundColor: "#d9d9d9",
        }}
      ></span>
    ))}
  </div>
);

const Loading = () => {
  return <div>Loading...</div>;
};

export default Loading;
