/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */

import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

const formatValue = (value: number): string => {
  if (value >= 1000000) {
    return `${Math.round(value / 100000) / 10}M`;
  } else if (value >= 1000) {
    return `${Math.round(value / 100) / 10}k`;
  } else {
    return value.toFixed(2);
  }
};
const plugin = {
  id: "exceptions",
  afterDraw: (chart: any) => {
    const { width, height, ctx } = chart;
    ctx.restore();

    ctx.font = "bold 30px Roboto";
    ctx.textBaseline = "middle";

    if (chart.config.data.datasets && chart.config.data.datasets.length > 0) {
      const dataset = chart.config.data.datasets[0].data;

      if (Array.isArray(dataset) && dataset.length > 0) {
        const total = dataset.reduce((a: number, b: number) => a + b, 0);

        const text1 = `₹${formatValue(total)}`;
        const text2 = `Capital`;

        const text1X = Math.round((width - ctx.measureText(text1).width) / 2);
        const text1Y = (height - chart.legend.height) / 2 - 10;

        ctx.fillText(text1, text1X, text1Y);

        ctx.font = "20px Roboto";
        const text2X = Math.round((width - ctx.measureText(text2).width) / 2);
        const spaceBetween = 30;
        const text2Y = text1Y + spaceBetween;

        ctx.fillText(text2, text2X, text2Y);
      }
    }
    ctx.save();
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "60%",
  animation: {
    animateScale: true,
    animateRotate: true,
    duration: 2000, // Duration of animations in milliseconds
    easeBounceInOut: true,
  },
  plugins: {
    datalabels: {
      color: (context: any) => {
        const backgroundColor =
          context.chart.data.datasets[0].backgroundColor[context.dataIndex];
        return backgroundColor === "#3354F4"
          ? "white"
          : backgroundColor === "#F0F4F4"
            ? "black"
            : "black";
      },
      formatter: (value: any, context: any) => {
        const total = context.chart.data.datasets[0].data.reduce(
          (a: number, b: number) => a + b,
          0,
        );
        return "";
      },
      font: {
        size: 14,
        weight: "bold" as const,
      },
    },
    legend: {
      display: true,
      position: "bottom" as const,
      labels: {
        font: {
          size: 16,
          weight: "bold" as const,
        },
        generateLabels: (chart: any) => {
          const data = chart.data;
          return data.labels.map((label: string | number, index: number) => {
            const value = data.datasets[0].data[index];
            return {
              text: `₹${formatValue(value)} ${label}`,
              fillStyle: data.datasets[0].backgroundColor[index],
              strokeStyle: data.datasets[0].borderColor[index],
              hidden: false,
              index,
            };
          });
        },
      },
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: (tooltipItem: any) => {
          const value = tooltipItem.raw;
          return `₹${value.toFixed(2)}`;
        },
      },
    },
    atalabels: {
      display: true,
      color: "black",
      font: {
        size: 20,
      },
      padding: 6,
      formatter: (value: number) => {
        return `${Math.round(value)}%`;
      },
    },
    animations: {
      tension: {
        duration: 1000,
        easing: "easeOutCubic",
      },
    },
  },
};

export type DoughnutDataSetType = {
  label: string;
  data: number[];
  borderColor: string[];
  backgroundColor: string[];
};

export type DoughnutData = {
  labels: string[];
  datasets: DoughnutDataSetType[];
};

type DoughnutChartProps = {
  data: DoughnutData;
};

const DoughnutChart = (props: DoughnutChartProps) => {
  const { data } = props;
  return (
    <div style={{ width: "100%", height: "300px" }}>
      <Doughnut data={data} options={options} plugins={[plugin]} />
    </div>
  );
};

export default DoughnutChart;
