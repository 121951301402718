import Image from "next/image";
import React, { useState, useEffect, FC } from "react";
// import "./Footer.css";

// interface Link {
//   id: number;
//   text: string;
//   path: string;
// }

// interface SocialIcon {
//   id: number;
//   src: string;
//   alt: string;
//   url: string;
// }

// interface FooterData {
//   links: Link[];
//   socialIcons: SocialIcon[];
// }

// const footerData: FooterData = {
//   links: [
//     {
//       id: 1,
//       text: "Terms of service",
//       path: "/terms-and-condition.html",
//     },
//     {
//       id: 2,
//       text: "Privacy policy",
//       path: "/privacy-policy.html",
//     },
//   ],

//   socialIcons: [
//     {
//       id: 1,
//       src: "youtube.svg",
//       alt: "YouTube",
//       url: "https://www.youtube.com/@abillionTA",
//     },
//     {
//       id: 2,
//       src: "twitter.svg",
//       alt: "Twitter",
//       url: "https://twitter.com/AbillionTA",
//     },
//     {
//       id: 3,
//       src: "linkedin.svg",
//       alt: "LinkedIn",
//       url: "https://www.linkedin.com/showcase/abillion-trade-analytics",
//     },
//     {
//       id: 4,
//       src: "telegram.svg",
//       alt: "Telegram",
//       url: "https://web.telegram.org/a/#-1001932861999",
//     },
//   ],
// };

const Footer: FC = () => {
  const [isIOS, setIsIOS] = useState<boolean>(false);
  // const imagePath = "/assets/images/";

  const handle = (): void => {
    const iOSAppURLScheme = "abillion-app://";
    const androidAppURLScheme = "abillion-app://";
    const iOSAppStoreURL =
      "http://apps.apple.com/us/app/abillion-trade-analytics/id6464286930";
    const androidPlayStoreURL =
      "http://play.google.com/store/apps/details?id=com.app.abillion";

    let appURLScheme: string;
    let appStoreURL: string;

    if (/iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
      appURLScheme = iOSAppURLScheme;
      appStoreURL = iOSAppStoreURL;
    } else if (/Android/i.test(navigator.userAgent)) {
      appURLScheme = androidAppURLScheme;
      appStoreURL = androidPlayStoreURL;
    } else {
      window.location.href = "https://abillionanalytics.com/";
      return;
    }

    window.location.href = appURLScheme;

    setTimeout(() => {
      window.location.href = appStoreURL;
    }, 1000);
  };

  useEffect(() => {
    setIsIOS(
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !/Android/i.test(navigator.userAgent),
    );
  }, []);

  return (
    <div
      className={
        isIOS ? "download-app-container-iphone" : "download-app-container"
      }
    >
      <div className="footer-logo">
        <Image src="/images/ab-logo.svg" alt="" width={26} height={18} />
      </div>

      <div className="app-content">
        <h4>Get App Now!</h4>
      </div>
      <span onClick={handle} className="download-fixed-btn">
        Download Now
      </span>
    </div>
  );
};

export default React.memo(Footer);
