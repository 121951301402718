/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */

import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const formatValue = (value: number): string => {
  if (value >= 1000000) {
    return `${Math.round(value / 100000) / 10}M`;
  } else if (value >= 1000) {
    return `${Math.round(value / 100) / 10}k`;
  } else {
    return value.toFixed(2);
  }
};

const plugin = {
  id: "corsair",
  defaults: {
    width: 1,
    color: "#FF4949",
    dash: [3, 3],
  },
  afterInit: (chart: any, args: any, opts: any) => {
    chart.corsair = {
      x: 0,
      y: 0,
      draw: false,
    };
  },
  afterEvent: (chart: any, args: any) => {
    const { inChartArea } = args;
    const { type, x, y } = args.event;

    chart.corsair = { x, y, draw: inChartArea };
    chart.draw();
  },
  beforeDatasetsDraw: (chart: any, args: any, opts: any) => {
    const { ctx } = chart;
    const { top, bottom, left, right } = chart.chartArea;
    const { x, y, draw } = chart.corsair || { x: 0, y: 0, draw: false };
    if (!draw) return;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = opts.width;
    ctx.strokeStyle = opts.color;
    ctx.setLineDash(opts.dash);
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    // ctx.moveTo(left, y)
    // ctx.lineTo(right, y)
    ctx.stroke();

    ctx.restore();
  },
};

export type VolumeDataSetType = {
  label: string;
  data: string[];
  borderWidth?: number;
  barThickness?: number;
  categoryPercentage?: number;
  barPercentage?: number;
  backgroundColor?: string[];
};

export type VolumeData = {
  labels: string[];
  datasets: VolumeDataSetType[];
};

type VolumeChartProps = {
  data: VolumeData;
};

const VolumeChart = (props: VolumeChartProps) => {
  const { data } = props;
  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        corsair: { color: "black" },
        datalabels: { display: false },
      },
      elements: {
        line: { tension: 0.5 },
      },
      hover: {
        mode: "index" as const,
        intersect: false,
      },
      interaction: {
        mode: "index" as const,
        intersect: false,
      },
      cubicInterpolationMode: "monotone",
      scales: {
        x: {
          display: false,
          stacked: true,
          easing: "linear",
        },
        y: {
          easing: "linear",
          ticks: {
            callback: (value: number | string) =>
              `${formatValue(Number(value))}`,
          },
          beginAtZero: true,
        },
      },
      animation: {
        duration: 1000,
        easeOutCubic: true,
      },
    }),
    [],
  );

  return <Bar options={options} plugins={[plugin]} data={data} />;
};
export default React.memo(VolumeChart);
