/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
);

const plugin = {
  id: "corsair",
  defaults: {
    width: 1,
    color: "#FF4949",
    dash: [3, 3],
  },
  afterInit: (chart: any, args: any, opts: any) => {
    chart.corsair = {
      x: 0,
      y: 0,
      draw: false,
    };
  },
  afterEvent: (chart: any, args: any) => {
    const { inChartArea } = args;
    const { type, x, y } = args.event;

    chart.corsair = { x, y, draw: inChartArea };
    chart.draw();
  },
  beforeDatasetsDraw: (chart: any, args: any, opts: any) => {
    const { ctx } = chart;
    const { top, bottom, left, right } = chart.chartArea;
    const { x, y, draw } = chart.corsair || { x: 0, y: 0, draw: false };
    if (!draw) return;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = opts.width;
    ctx.strokeStyle = opts.color;
    ctx.setLineDash(opts.dash);
    ctx.moveTo(x, bottom);
    ctx.lineTo(x, top);
    ctx.stroke();

    ctx.restore();
  },
};

const isValuationGradientBackground = (ctx: any) => {
  const chartCtx = ctx.chart.ctx;
  const { chartArea } = ctx.chart;

  if (!chartArea) {
    return;
  }

  const gradient = chartCtx.createLinearGradient(
    0,
    chartArea.bottom,
    0,
    chartArea.top,
  );
  gradient.addColorStop(0, "rgba(51, 84, 244, 0.0001)");
  gradient.addColorStop(1, "#3354F4");
  return gradient;
};

const customDataLabelPlugin = {
  id: "customDataLabel",
  afterDatasetsDraw(chart: any) {
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset: any, datasetIndex: any) => {
      if (dataset.label === "Buy Price") {
        dataset.data.forEach((value: any, index: any) => {
          if (value !== null) {
            const meta = chart.getDatasetMeta(datasetIndex);
            const data = meta.data[index];
            const { x, y } = data.getProps(["x", "y"], true);

            ctx.save();

            ctx.fillStyle = "rgba(153, 102, 255, 0.8)";
            ctx.strokeStyle = "rgba(153, 102, 255, 1)";
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.roundRect(x - 20, y - 30, 60, 40, 5);
            ctx.fill();
            ctx.stroke();
            ctx.fillStyle = "white";
            ctx.font = "12px Arial";

            const date = chart.data.labels[index];
            ctx.fillText(value.toFixed(2), x - 15, y - 15);
            ctx.fillText(date, x - 15, y - 5);

            ctx.restore();
          }
        });
      }
    });
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
    },
    corsair: {
      color: "black",
    },
    datalabels: {
      display: false,
    },
  },
  elements: {
    line: {
      fill: true,
      borderColor: "rgb(4, 96, 204)",
      backgroundColor: (ctx: any) => isValuationGradientBackground(ctx),
      tension: 0.2,
    },
  },
  hover: {
    mode: "index" as const,
    intersect: false,
  },
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  cubicInterpolationMode: "monotone",
  scales: {
    x: {
      stacked: true,

      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      title: {
        display: false, // Hide the y-axis label
      },
      ticks: {
        callback: (value: number | string) => {
          return value;
        },
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
  animation: {
    duration: 1000,
    easing: "easeOutCubic",
  } as const,
};

export type LineDataSetTypeConsumer = {
  label: string;
  data: number[];
  borderColor: string;
  borderWidth?: number;
  backgroundColor?: string;
  pointRadius?: number;
  pointStyle?: string;
  spanGaps?: boolean;
  pointHoverRadius?: number;
  pointBackgroundColor?: string[];
};

export type LineDataConsumer = {
  labels: string[];
  datasets: any[];
};

type LineChartPropsConsumer = {
  data: LineDataConsumer;
};

const LineChart = (props: LineChartPropsConsumer) => {
  const { data } = props;

  return (
    <Line
      options={options}
      // plugins={[plugin, customDataLabelPlugin]}
      plugins={[plugin]}
      data={data}
    />
  );
};
export default React.memo(LineChart);
