import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useContext,
  useState,
} from "react";
import Cookies from "js-cookie";
import { auth } from "../../helpers/firebase";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { graphqlApiCall } from "src/services/graphql.service";
import AppContext from "src/components/AppContext";
import { alertService } from "src/services";

interface ContactData {
  full_name: string;
  email: string;
  mobile_number: string;
  interested_for: string;
  user_id: string;
}
const ConsumerForm = () => {
  const context = useContext(AppContext);
  const { setShowLoader } = context.state;
  const [data, setData] = useState<ContactData>({
    full_name: "",
    email: "",
    mobile_number: "",
    interested_for: "enterprise",
    user_id: "",
  });

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev) {
      const { name } = ev.currentTarget;
      let value = ev.currentTarget.value;
      if (name === "mobile_number") {
        value = value.replace(/[^0-9]/g, "");
        if (value.length > 10) return;
      }
      setData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const onVerificationCodeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev) {
      const value = ev.currentTarget.value.replace(/[^0-9]/g, "");
      if (value.length > 6) return;
      setVerificationCode(value);
    }
  };

  const handleSwitch = (ev: MouseEvent<HTMLInputElement>) => {
    const value = ev.currentTarget.checked ? "retail" : "enterprise";
    setData((prevData) => ({
      ...prevData,
      interested_for: value,
    }));
  };

  const handleFormSubmit = async (uid: string) => {
    try {
      if (setShowLoader) setShowLoader(true);
      const response = await graphqlApiCall({
        node: "userByUserId",
        columns: ["userId"],
        id: `userId: "${uid}"`,
      });
      if (response) {
        await graphqlApiCall({
          node: "updateUserByUserId",
          columns: ["user {", "userId", "}"],
          input: `userPatch: {name: "${data.full_name}", interestedFor: "${data.interested_for}"}, userId: "${uid}"`,
        });
      } else {
        await graphqlApiCall({
          node: "createUser",
          columns: ["user {", "userId", "}"],
          input: `user: {userId: "${uid}", name: "${data.full_name}", email: "${data.email}", mobileNumber: "${data.mobile_number}", interestedFor: "${data.interested_for}", exitPercentage: 1000000, investedAmount: 0, stopLossPercentage: 7, timeHorizon: 5}`,
        });
      }
      Cookies.set("contact", JSON.stringify(data), { expires: 7 });
      window.location.reload();
    } catch (error) {
      alertService.error("Something went wrong. Please try again later.");
      // console.error("Error during submit: ", error);
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  };

  const [verificationCode, setVerificationCode] = useState<string>("");
  const [confirmResult, setConfirmResult] = useState<ConfirmationResult>();
  const setRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
    );
  };
  const handleSendCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (setShowLoader) setShowLoader(true);
    setRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        `+91${data.mobile_number}`,
        appVerifier,
      );
      setConfirmResult(confirmationResult);
    } catch {
      alertService.error("Please enter valid mobile number.");
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  };
  const handleVerifyCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!confirmResult) {
      alertService.error("Please enter correct code.");
      return;
    }
    try {
      if (setShowLoader) setShowLoader(true);
      const result = await confirmResult.confirm(verificationCode);
      const user = result.user;
      setData((prevData) => ({ ...prevData, user_id: user.uid }));
      await handleFormSubmit(user.uid);
    } catch {
      alertService.error("Please enter correct code.");
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  };

  return (
    <div className="col-12">
      {!confirmResult ? (
        <form onSubmit={handleSendCode}>
          <div className="mb-3">
            <div className="position-relative">
              <i className="fa-solid fa-user"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Full Name *"
                id="contact_name"
                name="full_name"
                value={data.full_name}
                onChange={onChange}
                required
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="position-relative">
              <i className="fa-solid fa-phone"></i>
              <input
                type="text"
                placeholder="Mobile Number *"
                className="form-control"
                id="contact_mobile"
                name="mobile_number"
                value={data.mobile_number}
                onChange={onChange}
                required
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="position-relative">
              <i className="fa-solid fa-envelope"></i>
              <input
                type="email"
                placeholder="Email"
                className="form-control"
                id="contact_email"
                name="email"
                value={data.email}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center gap-2">
            <label className="form-check-label me-2">Enterprise</label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                data-toggle="toggle"
                defaultChecked
                id="flexSwitchCheckDefault"
                onClick={handleSwitch}
              />
            </div>
            <label className="form-check-label">Retail</label>
          </div>
          <div className="mb-3">
            <div id="recaptcha-container"></div>
          </div>
          <div className="d-grid">
            <button
              type="submit"
              disabled={data.mobile_number.length != 10}
              className={`btn btn-${data.mobile_number.length === 10 ? "success" : "secondary"}`}
            >
              Submit
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={handleVerifyCode}>
          <div className="mb-3">
            <label htmlFor="contact_name" className="form-label">
              Verification Code
            </label>
            <input
              type="text"
              placeholder="Verification Code"
              className="form-control"
              value={verificationCode}
              onChange={onVerificationCodeChange}
              required
            />
          </div>
          <div className="d-grid">
            <button
              type="submit"
              className={`btn btn-${verificationCode.length == 6 ? "success" : "secondary"}`}
              disabled={verificationCode.length != 6}
            >
              Verify Code
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
export default React.memo(ConsumerForm);
