/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

const formatValue = (value: number): string => {
  if (value >= 1000000) {
    return `${Math.round(value / 100000) / 10}M`;
  } else if (value >= 1000) {
    return `${Math.round(value / 100) / 10}k`;
  } else {
    return value.toFixed(2);
  }
};

const dataLabelPlugin = {
  id: "dataLabelPlugin",
  afterDatasetsDraw(chart: any) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset: any, i: number) => {
      const meta = chart.getDatasetMeta(i);
      meta.data.forEach((bar: any, index: number) => {
        const value = dataset.data[index];
        if (value > 0) {
          if (value == 10) {
            return false;
          }
          const label = `₹${formatValue((dataset.investment * (100 + Number(value))) / 100)}`;

          ctx.font = "bold 12px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";

          const x = bar.x;
          const y = bar.y + 1;

          ctx.fillText(label, x, y);
        }
      });
    });
  },
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: (tooltipItem: any) => {
          const value = tooltipItem.raw;
          return `${Math.round(Number(value))}%`;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
  animation: {
    duration: 1000,
    easing: "easeInOutCubic",
  } as const,
};

export type BarDataSetType = {
  label: string;
  data: number[];
  backgroundColor: string[];
  investment?: number | string;
};

export type BarData = {
  labels: string[];
  datasets: BarDataSetType[];
};

type BarChartProps = {
  data: BarData;
};

const BarChart = (props: BarChartProps) => {
  const { data } = props;

  if (!data.datasets || data.datasets.length === 0) {
    return null;
  }

  const originalData = data.datasets[0].data;
  const investment = data.datasets;

  const updatedDatasets = [
    {
      label: "",
      data: originalData.map((value) => Math.min(value, 10).toFixed(2)),
      backgroundColor: "#6794DC",
      investment: investment[0].investment,
    },
    {
      label: "",
      data: originalData.map((value) => Math.max(0, value).toFixed(2)),
      backgroundColor: "#67B7DC",
      investment: investment[0].investment,
    },
  ];

  return (
    <Bar
      options={options}
      data={{ labels: data.labels, datasets: updatedDatasets }}
      plugins={[dataLabelPlugin]}
    />
  );
};

export default React.memo(BarChart);
