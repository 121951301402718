import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  useMemo,
} from "react";
import Image from "next/image";
import { AgGridReact } from "ag-grid-react";
import { stockSummary } from "src/helpers";
import { ColDef } from "ag-grid-community";
import { graphqlApiCall } from "src/services/graphql.service";
import AppContext from "src/components/AppContext";
import { dateComparator, dateFilterParams } from "helpers/hps/agGridFilters";
import moment from "moment";
import LineChart, { LineDataConsumer } from "./Charts/LineChart";
import BarChart, { BarDataSetType, BarData } from "./Charts/BarChart";
import DoughnutChart, {
  DoughnutData,
  DoughnutDataSetType,
} from "./Charts/DoughnutChart";
import ConsumerForm from "./form";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";
import LineChartJS, {
  LineDataSetType,
  LineData,
} from "helpers/hps/charts/LineChartJS";
import Footer from "./footer";
import VolumeChart, {
  VolumeDataSetType,
  VolumeData,
} from "./Charts/VolumeChart";
import { Loader, SkeletonBarLoader } from "./Loader";

interface StockSummary {
  entryDate: string;
  buyPrice: number;
}

interface CellRendererParams<T> {
  node?: {
    rowIndex: number;
  };
  data: T;
}

const Consumer = () => {
  const [contact] = useState<string>(Cookies.get("contact") || "");

  const defaultBarData: BarData = {
    labels: [],
    datasets: [],
  };

  const defaultDoughnutData: DoughnutData = {
    labels: [],
    datasets: [],
  };

  const defaultLineData: LineDataConsumer = {
    labels: [],
    datasets: [],
  };

  const defaultValue: stockSummary[] = [
    {
      id: "",
      stockName: "Oil India Limited",
      stockSymbol: "OIL",
      ltp: 0,
      buyPrice: 0,
      entryDate: "2024-05-03T00:00:00+00:00",
    },
  ];

  type DataPoint = {
    nifty50: number;
    nifty200: number;
    niftyMidSml400: number;
    abIndex: number;
    date: string;
  };

  type HistoricalData = {
    date: string;
    symbolName: string;
    close: string;
  };

  interface BuyPriceEntry {
    price: number;
    color: string;
    id: string;
  }

  type BuyPrices = Record<string, BuyPriceEntry>;

  const [barChartAPIData, setBarChartAPIData] =
    useState<BarData>(defaultBarData);
  const [volumeChartData, setVolumeChartData] = useState<VolumeData>();

  const [investmentOverTime, setInvestmentOverTime] =
    useState<LineDataConsumer>(defaultLineData);
  const [chartData, setChartData] = useState<DoughnutData>(defaultDoughnutData);
  const context = useContext(AppContext);
  const { setShowLoader } = context.state;
  const [search, setSearch] = useState<string>("");
  const [stockNameSuggestion, setStockNameSuggestion] =
    useState<stockSummary[]>(defaultValue);
  const [percentageGain, setPercentageGain] = useState<number>(0);
  const [selectedStock, setSelectedStock] =
    useState<stockSummary[]>(defaultValue);
  const [filteredData, setFilterdData] = useState<stockSummary[]>(defaultValue);
  const [inputOpen, setInputOpen] = useState<{
    invest: boolean;
    gain: boolean;
    margin: boolean;
  }>({ invest: false, gain: false, margin: false });
  const [autoCompelete, setAutoCompelte] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<{
    invest: string | number;
    gain: string | number;
    margin: string | number;
  }>({ invest: 0, gain: 0, margin: 0 });

  const investRef = useRef<HTMLInputElement>(null);
  const gainRef = useRef<HTMLInputElement>(null);
  const marginRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const autoCompeleteRef = useRef<HTMLInputElement>(null);

  const [stockLTP, setStockLtp] = useState<number>(0);
  const [selectedRowId, setSelectedRowId] = useState<number | string>();
  const [lineChartModal, setLineChartModal] = useState<boolean>(false);
  const [lineChartTabData, setLineChartTabData] = useState<LineData>();
  const [refreshHistorical, setRefreshHistorical] = useState<boolean>(true);
  const [historicalData, setHistoricalData] = useState<HistoricalData>();
  const [refreshToken, setRefreshToken] = useState<number>();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [callCount, setCallCount] = useState(2);
  const [isLoading, setIsLoading] = useState({
    line: true,
    bar: false,
    dount: false,
  });

  const colors = useMemo(
    () => [
      "#F8285A",
      "#17C653",
      "#7239EA",
      "#26D7FB",
      "#F6C000",
      "#1E2129",
      "#F04438",
      "#DC6803",
      "#12B76A",
      "#669F2A",
      "#4E5BA6",
      "#D444F1",
      "#36BFFA",
      "#15B79E",
      "#FD6F8E",
      "#D732A8",
      "#BF6A02",
      "#AF52DE",
      "#00C7BE",
      "#35F999",
      "#EE0B6E",
      "#D3B60F",
      "#C767DC",
      "#28F856",
      "#2400FF",
    ],
    [],
  );

  const handleOnRowClick = (rowId: number | string) => {
    setSelectedRowId(rowId);
  };

  const LineChartTabData = useCallback(
    async (start: string, end: string) => {
      if (setShowLoader) setShowLoader(true);

      try {
        const response: DataPoint[] = await graphqlApiCall({
          node: "getIndexChangeInDuration",
          columns: ["nifty50", "nifty200", "niftyMidSml400", "abIndex", "date"],
          conditions: `dFrom: "${moment(start).format("YYYY-MM-01")}", dTo: "${moment(end).format("YYYY-MM-01")}"`,
        });
        const labels = response.map((d) => moment(d.date).format("MMM-YYYY"));
        const datasets: LineDataSetType[] = [
          {
            label: "Nifty 50",
            data: response.map((d) => d.nifty50),
            borderColor: "#24C1E0",
            backgroundColor: "#24C1E0",
          },
          {
            label: "Nifty 200",
            data: response.map((d) => d.nifty200),
            borderColor: "#FA7B17",
            backgroundColor: "#FA7B17",
          },
          {
            label: "Nifty Mid-Small 400",
            data: response.map((d) => d.niftyMidSml400),
            borderColor: "#8430CE",
            backgroundColor: "#8430CE",
          },
          {
            label: "Abillion",
            data: response.map((d) => d.abIndex),
            borderColor: "#198754",
            backgroundColor: "#198754",
          },
        ];
        setLineChartTabData({
          labels,
          datasets: datasets,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (setShowLoader) setShowLoader(false);
      }
    },
    [setShowLoader],
  );

  const getHistoricalData = useCallback(
    async (stock?: stockSummary) => {
      const stockObj = stock || selectedStock[selectedStock.length - 1];
      if (stock || refreshHistorical) {
        const response = await graphqlApiCall({
          node: `searchAllstocksHistoricalData`,
          columns: ["date", "symbolName", "close", "volume"],
          conditions: `stockSymbol: "${stockObj.stockSymbol}", fromDate: "${stockObj.entryDate}"`,
        });
        setHistoricalData(response);
        return response;
      } else {
        return historicalData;
      }
    },
    [historicalData, refreshHistorical, selectedStock],
  );

  const lineChartData = useCallback(async () => {
    if (!selectedStock || !selectedStock.length) return;

    setIsLoading((prev) => ({ ...prev, line: true }));

    try {
      const response = await getHistoricalData();
      if (response.length) {
        const lastCloseValue = response[response.length - 1]?.close;
        if (refreshHistorical)
          LineChartTabData(
            response[0].date,
            response[response.length - 1].date,
          );
        if (isFirstCall) {
          setStockLtp(lastCloseValue);
        }

        const startDate = moment(
          selectedStock[selectedStock.length - 1]?.entryDate,
        );
        const endDate = moment();
        const allDates = [];

        for (let m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
          allDates.push(m.format("DD-MMM-YY"));
        }

        const closePrices = response.reduce(
          (acc: Record<string, string>, d: { date: string; close: string }) => {
            acc[moment(d.date).format("DD-MMM-YY")] = d.close;
            return acc;
          },
          {},
        );

        const volumes: Record<string, string> = response.reduce(
          (
            acc: Record<string, string>,
            d: { date: string; volume: string },
          ) => {
            acc[moment(d.date).format("DD-MMM-YY")] = d.volume;
            return acc;
          },
          {},
        );

        const buyPrices = selectedStock.reduce<BuyPrices>((acc, s, index) => {
          acc[moment(s.entryDate).format("DD-MMM-YY")] = {
            price: s.buyPrice,
            color: colors[index % colors.length],
            id: s.id,
          };
          return acc;
        }, {});

        const closeData = allDates.map((date) => closePrices[date] || null);
        const buyData = allDates.map((date) => {
          const buyInfo = buyPrices[date];

          return buyInfo
            ? { price: buyInfo.price, color: buyInfo.color, id: buyInfo.id }
            : null;
        });

        const datasets = [
          {
            label: "Close Price",
            data: closeData,
            borderColor: "#3354F4",
            borderWidth: 0,
            pointRadius: 0,
            pointHoverRadius: 1,
            spanGaps: true,
          },
          {
            label: "Buy Price",
            data: buyData.map((dataPoint) =>
              dataPoint ? dataPoint.price : null,
            ),
            borderColor: buyData.map((dataPoint) =>
              dataPoint ? dataPoint.color : "#000",
            ),
            backgroundColor: buyData.map((dataPoint) =>
              dataPoint ? dataPoint.color : "#000",
            ),
            fill: true,
            pointStyle: "circle",
            pointRadius: buyData.map((dataPoint) => {
              return dataPoint?.id === selectedRowId ? 20 : 10;
            }),
            pointHoverRadius: selectedRowId ? 30 : 20,
            pointHover: (
              dataPoint: { price: number | null } | null,
              index: number,
            ) => {
              return dataPoint && index === selectedRowId;
            },
          },
        ];

        if (callCount > 1) {
          setInvestmentOverTime({
            labels: allDates,
            datasets: datasets,
          });
        }

        const volumeDatasets: VolumeDataSetType[] = [
          {
            label: "Volume",
            data: allDates.map((date) => volumes[date]),
            borderWidth: 2,
            barThickness: 1.5,
            categoryPercentage: 0.5, // Controls width of the bars
            barPercentage: 0.5,
            backgroundColor: buyData.map((dataPoint) =>
              dataPoint ? dataPoint.color : "#E3E9EF",
            ),
          },
        ];
        setVolumeChartData({
          labels: allDates.map((d) => moment(d).format("DD-MMM-YYYY")) || [],
          datasets: volumeDatasets,
        });
        return lastCloseValue;
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (setShowLoader) setShowLoader(false);
    }
  }, [
    selectedStock,
    selectedRowId,
    callCount,
    getHistoricalData,
    refreshHistorical,
    isFirstCall,
    LineChartTabData,
    colors,
    setShowLoader,
  ]);

  const fetchAllStockData = useCallback(
    async (stockName: string) => {
      setIsSkeleton(true);
      const response = await graphqlApiCall({
        node: `searchHpsAllstocks`,
        columns: [
          "id",
          "stockName",
          "stockSymbol",
          "ltp",
          "buyPrice",
          "entryDate",
        ],
        conditions: `search: "${stockName}" `,
      });
      setStockNameSuggestion(response);
      setSelectedStock(response);
      lineChartData();

      const lastCloseValue = await lineChartData();

      if (lastCloseValue && response[response.length - 1]?.buyPrice > 0) {
        const percentage = Math.abs(
          ((lastCloseValue - response[response.length - 1]?.buyPrice) * 100) /
            response[response.length - 1]?.buyPrice,
        );
        setPercentageGain(percentage);
        const gain: number = (50000 * percentage) / 100;
        setInputValue({
          invest: 50000,
          gain: gain,
          margin: 50000 + gain,
        });
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    },
    [lineChartData],
  );

  useEffect(() => {
    if (isFirstCall) {
      const fetchData = async () => {
        const lastCloseValue = await lineChartData();

        if (lastCloseValue) {
          setStockLtp(lastCloseValue);
          fetchAllStockData("Oil India Limited");
        }
      };

      fetchData();
    }
  }, [lineChartData, isFirstCall, fetchAllStockData]);

  useEffect(() => {
    if (selectedStock.length > 0 && stockLTP > 0) {
      if (isFirstCall === true) {
        const buyPrice = selectedStock[selectedStock.length - 1]?.buyPrice;
        if (buyPrice > 0) {
          const percentage = Math.abs(((stockLTP - buyPrice) * 100) / buyPrice);

          setPercentageGain(percentage);
          const gain = (50000 * percentage) / 100;
          setInputValue({
            invest: 50000,
            gain: gain,
            margin: 50000 + gain,
          });
          setIsFirstCall(false);
        }
      }
    }
  }, [stockLTP, selectedStock, isFirstCall]);

  const columnDefs: ColDef<stockSummary>[] = [
    {
      headerName: "",
      minWidth: 20,
      maxWidth: 20,
      cellRenderer: (params: CellRendererParams<StockSummary>) => {
        const colorIndex =
          params.node?.rowIndex !== undefined
            ? params.node.rowIndex % colors.length
            : 0;

        return (
          <div
            style={{
              width: "10px",
              backgroundColor: colors[colorIndex],
            }}
          ></div>
        );
      },
    },
    {
      field: "entryDate",
      headerName: "Date",
      filterParams: dateFilterParams,
      comparator: dateComparator,
      cellRenderer: (params: { data: stockSummary }) => {
        return (
          params.data.entryDate &&
          moment(params.data.entryDate).format("DD MMM,YYYY")
        );
      },
      minWidth: 100,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "buyPrice",
      headerName: "Entry Price",
      minWidth: 100,
      cellRenderer: (params: { data: stockSummary }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {params.data.buyPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      headerName: "Return",
      minWidth: 30,
      cellRenderer: (params: { data: { ltp: number; buyPrice: number } }) => {
        const percentageReturn =
          selectedStock &&
          Math.abs(
            ((stockLTP - params.data?.buyPrice) * 100) / params.data?.buyPrice,
          );
        return (
          <div style={{ color: percentageReturn > 0 ? "green" : "red" }}>
            {percentageReturn.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            %
          </div>
        );
      },
    },
  ];
  const gridRef = useRef<AgGridReact<stockSummary>>(null);

  const fetchBarChartData = useCallback(async () => {
    const lastCloseValue = await lineChartData();
    const selectedStockData = [...selectedStock].reverse();

    if (lastCloseValue) {
      const labels =
        selectedStockData.map((d) =>
          moment(d.entryDate).format("DD-MMM-YYYY"),
        ) || [];

      const datasets: BarDataSetType[] = [
        {
          label: "",
          data:
            selectedStockData.map((d) => {
              return Math.abs(((stockLTP - d?.buyPrice) * 100) / d?.buyPrice);
            }) || [],
          // borderColor: "#24C1E0",
          backgroundColor:
            selectedStockData.map((d) => {
              return Math.abs(((stockLTP - d?.buyPrice) * 100) / d?.buyPrice) >
                10
                ? "#67B7DC"
                : "#6794DC";
            }) || [],
          investment: inputValue.invest || "",
        },
      ];

      setBarChartAPIData({
        labels,
        datasets,
      });
    }
  }, [selectedStock, stockLTP, inputValue.invest, lineChartData]);

  const fetchDoughnutChartData = useCallback(async () => {
    setIsLoading((prev) => ({ ...prev, dount: true }));
    const lastCloseValue = await lineChartData();
    const gained = Number(inputValue.gain);
    const invested = Number(inputValue.invest);
    const doughnutChartData: { name: string; value: number }[] = [
      {
        name: "Gained",
        value: gained,
      },
      {
        name: "Invested",
        value: invested,
      },
    ];

    if (lastCloseValue) {
      const labels = doughnutChartData.map((d) => `${d.name}`);
      const data = doughnutChartData.map((d) => d.value);
      const datasets: DoughnutDataSetType[] = [
        {
          label: "",
          data: data,
          borderColor: ["#3354F4", "#F0F4F4"],
          backgroundColor: ["#3354F4", "#F0F4F4"],
        },
      ];
      setChartData({
        labels,
        datasets: datasets,
      });
    }
    setIsLoading((prev) => ({ ...prev, dount: false }));
  }, [inputValue.invest, inputValue.gain, lineChartData]);

  useEffect(() => {
    fetchBarChartData();
  }, [fetchBarChartData]);

  useEffect(() => {
    fetchDoughnutChartData();
  }, [fetchDoughnutChartData]);

  const handleOnChangeSearchInput = async (search: string) => {
    const response = await graphqlApiCall({
      node: `searchHpsAllstocks`,
      columns: [
        "id",
        "stockName",
        "stockSymbol",
        "ltp",
        "buyPrice",
        "entryDate",
      ],
      conditions: `search: "${search}" `,
    });
    const uniqueStocks = Array.from(
      new Map(
        response.map((stock: stockSummary) => [stock.stockName, stock]),
      ).values(),
    ) as stockSummary[];
    setFilterdData(uniqueStocks.reverse());
    setStockNameSuggestion(response);
  };

  const handleOnClickStock = async (stockName: string) => {
    setIsLoading((prev) => ({ ...prev, line: true }));
    setIsSkeleton(true);
    setCallCount(2);
    const selectedStockFromAllData = stockNameSuggestion.filter(
      (data) => data.stockName === stockName,
    );
    setSelectedStock(selectedStockFromAllData);
    const response = await getHistoricalData(selectedStockFromAllData[0]);
    const lastCloseValue = response[response.length - 1]?.close;
    await lineChartData();

    if (lastCloseValue) {
      setIsFirstCall(false);
      setStockLtp(lastCloseValue);
      const percentage = Math.abs(
        ((lastCloseValue -
          selectedStockFromAllData[selectedStockFromAllData.length - 1]
            .buyPrice) *
          100) /
          selectedStockFromAllData[selectedStockFromAllData.length - 1]
            .buyPrice,
      );
      setPercentageGain(percentage);
      const gain: number = (50000 * percentage) / 100;
      setInputValue({
        invest: 50000,
        gain: gain,
        margin: 50000 + gain,
      });
      setSearch("");
    }
    setRefreshHistorical(true);
    setIsSkeleton(false);
  };

  useEffect(() => {
    setCallCount(0);
    setIsLoading((prev) => ({ ...prev, line: true }));
    if (selectedRowId) {
      setRefreshHistorical(false);
      lineChartData();
      setCallCount(2);
      if (callCount === 0) {
        setIsLoading((prev) => ({ ...prev, line: true }));
      } else {
        setIsLoading((prev) => ({ ...prev, line: false }));
      }
    }
  }, [selectedRowId, callCount, lineChartData]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      (investRef.current &&
        !investRef.current.contains(event.target as Node)) ||
      (gainRef.current && !gainRef.current.contains(event.target as Node)) ||
      (marginRef.current &&
        !marginRef.current.contains(event.target as Node)) ||
      (searchRef.current &&
        !searchRef.current.contains(event.target as Node) &&
        autoCompeleteRef.current &&
        !autoCompeleteRef.current.contains(event.target as Node))
    ) {
      setInputOpen({ invest: false, gain: false, margin: false });
      setAutoCompelte(false);
    }
  };

  const validateInput = (event: KeyboardEvent<HTMLInputElement>) => {
    const input = event.currentTarget.value;
    const key = event.key;

    if (
      [
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
      ].includes(key)
    ) {
      return; // Allow these keys
    }

    // If the input is empty and the key is '0', prevent it
    if (input.length === 0 && key === "0") {
      event.preventDefault();
      return;
    }

    // Prevent negative numbers
    if (key === "-" && input.length === 0) {
      event.preventDefault();
      return;
    }

    // // Prevent entering 0 as the first character if the input already has digits
    // if (input.length > 0 && key === '0' && input[0] !== '0') {
    //     event.preventDefault();
    //     return;
    // }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedStock.length > 0) {
        const rowIndex = Math.floor(Math.random() * (selectedStock.length - 1));
        handleOnRowClick(selectedStock[rowIndex].id);
      }
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [selectedStock.length, selectedStock]);

  useEffect(() => {
    const lineChartTab = document.getElementById("linechart-tab");
    const barChartTab = document.getElementById("barchart-tab");

    let toggle = true;
    const intervalId = setInterval(() => {
      setRefreshToken(Date.now() + Math.floor(Math.random() * 1000));
      if (toggle) {
        if (lineChartTab) lineChartTab.click(); // Click the first button
      } else {
        if (barChartTab) barChartTab.click(); // Click the second button
      }
      toggle = !toggle;
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (contact.length > 0) {
      document.getElementById("contact-modal")?.classList.remove("show");
      document.getElementById("contact-modal")?.classList.remove("d-block");
      document.getElementById("contact-modal")?.classList.add("d-none");
    } else {
      document.getElementById("contact-modal")?.classList.add("show");
      document.getElementById("contact-modal")?.classList.add("d-block");
    }
  }, [contact]);

  const openModal = () => {
    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <div
        id="contact-modal"
        className="modal ab-entry-popup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="m-0">Register Now!</h5>
            </div>
            <div className="modal-body">
              <ConsumerForm />
            </div>
          </div>
        </div>
      </div>
      // </Suspense>
    );
  };

  const calculate = (ev: ChangeEvent<HTMLInputElement>, field: string) => {
    setIsLoading((prev) => ({ ...prev, dount: true }));
    setIsSkeleton(true);
    const value = ev.currentTarget.value.replace(/[^0-9]/g, "");
    if (value.length > 10) return;
    if (ev.target.value) {
      let invest = parseFloat(ev.target.value);
      let gain = (invest * percentageGain) / 100;
      if (field === "gain") {
        gain = parseFloat(ev.target.value);
        invest = (gain * 100) / percentageGain;
      }
      setInputValue({
        invest: invest,
        gain: gain,
        margin: invest + gain,
      });
    } else {
      setInputValue((inputValue) => ({
        ...inputValue,
        invest: 0,
        gain: 0,
        margin: inputValue.margin,
      }));
    }
    setIsSkeleton(false);
    setIsLoading((prev) => ({ ...prev, dount: false }));
  };

  return (
    <div className="consumer-page-view">
      {openModal()}
      <nav className="ab-navbar d-flex p-2 p-md-3 shadow-sm align-items-center">
        <div className="ab-logo" style={{ cursor: "pointer" }}>
          <Image
            src="/images/ab-logo.svg"
            alt="Abillion"
            height={32}
            width={47}
          />
        </div>
        <div className="ab-search flex-fill">
          <div className="ab-search-in position-relative">
            <input
              ref={searchRef}
              placeholder="Type Stock Name"
              type="text"
              className="form-control"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);

                if (event.target.value.length > 2) {
                  setAutoCompelte(true);
                  handleOnChangeSearchInput(event.target.value);
                } else {
                  // setSelectedStock(defaultValue);
                  setAutoCompelte(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setAutoCompelte(false);
                }
              }}
            />
            <i className="fa fa-search"></i>
          </div>
          {autoCompelete && (
            <div ref={autoCompeleteRef} className="autoComplete-search">
              {filteredData?.map((stock, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      className="result-row"
                      onClick={(_event) => {
                        handleOnClickStock(stock.stockName),
                          setAutoCompelte(false);
                      }}
                    >
                      <span className="s-img">
                        <Image
                          src={`https://firebasestorage.googleapis.com/v0/b/abillion-analytics.appspot.com/o/${stock.stockSymbol}.jpg?alt=media`}
                          alt={stock.stockName}
                          width={180}
                          height={180}
                          unoptimized
                          style={{ height: "40px", width: "auto" }}
                        />
                      </span>

                      <span className="s-label">{stock.stockName}</span>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>
      </nav>
      <div className="ab-main">
        <div className="container-fluid">
          <div className="row g-3" style={{ height: "100%", overflow: "auto" }}>
            <div className="col-xl-3 d-flex flex-column gap-2 gap-md-3">
              <div className="card ab-card ab-card-comp">
                <div className="card-body d-flex flex-row gap-3 align-items-start">
                  <div className="flex-fill">
                    <h6>{selectedStock && selectedStock[0]?.stockName}</h6>
                    <div className="mb-3">
                      <h3>
                        ₹
                        {stockLTP.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h3>
                    </div>
                    <div
                      className={`ab-status ${percentageGain > 0 ? "success" : "danger"}`}
                    >
                      {selectedStock && (
                        <div className="ab-status-pill">
                          <i
                            className={
                              "fa " +
                              (percentageGain < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up")
                            }
                            aria-hidden="true"
                          ></i>
                          {percentageGain.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          %
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="ab-stock-icon ratio ratio-1x1">
                    {selectedStock && (
                      <Image
                        src={`https://firebasestorage.googleapis.com/v0/b/abillion-analytics.appspot.com/o/${selectedStock[0]?.stockSymbol}.jpg?alt=media`}
                        alt={selectedStock[0]?.stockName}
                        width={180}
                        height={180}
                        unoptimized
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="card ab-card ab-card-stock ab-card-stock-01">
                <div className="ab-stock-icon ab-stock-icon-ani ratio ratio-1x1">
                  <Image
                    src="/images/Investment_icon.gif"
                    alt="Investment"
                    width={160}
                    height={160}
                    unoptimized
                  />
                </div>
                <div className="card-body">
                  <h6>Investment</h6>
                  <div className="ab-calc-box">
                    {inputOpen.invest ? (
                      <input
                        ref={investRef}
                        type="text"
                        className="form-control"
                        value={Number(inputValue.invest)}
                        onChange={(e) => calculate(e, "invest")}
                        onKeyDown={validateInput}
                      />
                    ) : (
                      <h3
                        className="card-price"
                        onClick={() =>
                          setInputOpen((inputOpen) => ({
                            ...inputOpen,
                            invest: true,
                          }))
                        }
                      >
                        ₹
                        {inputValue.invest.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h3>
                    )}
                  </div>
                </div>
              </div>

              <div className="card ab-card ab-card-stock ab-card-stock-01">
                <div className="ab-stock-icon ab-stock-icon-ani ratio ratio-1x1">
                  <Image
                    src="/images/total_gain_icon.gif"
                    alt="Gain"
                    width={160}
                    height={160}
                    unoptimized
                  />
                </div>
                <div className="card-body">
                  <h6>Total Gain</h6>
                  <div className="ab-calc-box">
                    {isSkeleton ? (
                      <div
                        className="skeleton-loader"
                        style={{ height: "40px", width: "100%" }}
                      ></div>
                    ) : inputOpen.gain ? (
                      <input
                        ref={gainRef}
                        type="number"
                        className="form-control"
                        defaultValue={Number(inputValue.gain).toFixed(2)}
                        onChange={(e) => calculate(e, "gain")}
                        onKeyDown={validateInput}
                      />
                    ) : (
                      <h3
                        className="card-price"
                        onClick={() =>
                          setInputOpen((inputOpen) => ({
                            ...inputOpen,
                            gain: true,
                          }))
                        }
                      >
                        ₹
                        {inputValue.gain.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h3>
                    )}
                  </div>
                </div>
              </div>

              <div className="card ab-card ab-card-stock ab-card-stock-01">
                <div className="ab-stock-icon ab-stock-icon-ani ratio ratio-1x1">
                  <Image
                    src="/images/total_capital_icon.gif"
                    alt="Capital"
                    width={160}
                    height={160}
                    unoptimized
                  />
                </div>
                <div className="card-body">
                  <h6>Current Capital</h6>
                  <div className="ab-calc-box">
                    {isSkeleton ? (
                      <div
                        className="skeleton-loader"
                        style={{ height: "40px", width: "100%" }}
                      ></div>
                    ) : (
                      <>
                        <h3>
                          ₹
                          {(
                            Number(inputValue.invest) + Number(inputValue.gain)
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h3>
                        <div
                          className={`ab-status ${percentageGain > 0 ? "success" : "danger"}`}
                        >
                          {selectedStock && (
                            <span className="ab-status-pill">
                              <i
                                className={
                                  "fa " +
                                  (percentageGain < 0
                                    ? "fa-arrow-down"
                                    : "fa-arrow-up")
                                }
                                aria-hidden="true"
                              ></i>
                              {percentageGain.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                              %
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="card ab-card ab-card-ads ab-chart-card flex-fill">
                <div className="card-header">
                  <h6>Volumes</h6>
                </div>
                <div className="card-body ps-0 pe-0">
                  <div className="d-flex justify-content-center">
                    <div style={{ minHeight: "35vh", width: "90%" }}>
                      <VolumeChart
                        data={volumeChartData || { labels: [], datasets: [] }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-flex flex-column gap-3">
              <div className="card ab-chart-card flex-fill">
                <div className="card-header d-flex align-items-center">
                  <h6 className="flex-fill">Price Movement</h6>
                  <div style={{ textAlign: "end" }}>
                    <Button onClick={() => setLineChartModal(true)}>
                      <i className="fa-solid fa-plus"></i>
                    </Button>
                  </div>
                </div>
                <div className="card-body linechart-card-size">
                  {isLoading.line ? (
                    <SkeletonBarLoader />
                  ) : (
                    <LineChart data={investmentOverTime} />
                  )}
                </div>
              </div>
              <div className="card ab-chart-card flex-fill">
                <div className="card-header d-flex align-items-center">
                  <h6 className="flex-fill">Investment Over Time</h6>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="linechart-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#lineChart"
                        type="button"
                        role="tab"
                        aria-controls="lineChart"
                        aria-selected="true"
                      >
                        <span>
                          <i className="fa fa-lg fa-line-chart"></i>
                        </span>
                        <span className="text">Line Chart</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="barchart-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#barChart"
                        type="button"
                        role="tab"
                        aria-controls="barChart"
                        aria-selected="false"
                      >
                        <span>
                          <i className="fa fa-bar-chart"></i>
                        </span>
                        <span className="text">Bar Chart</span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body linechart-card-size">
                  <div className="tab-content" id="myTabContent">
                    {isLoading.bar ? (
                      <Loader />
                    ) : (
                      <>
                        <div
                          className="tab-pane fadeshow active data-grid linechart-card-size"
                          id="lineChart"
                          role="tabpanel"
                          aria-labelledby="line-chart"
                        >
                          <LineChartJS
                            key={refreshToken}
                            data={
                              lineChartTabData || { labels: [], datasets: [] }
                            }
                          />
                        </div>

                        <div
                          className="tab-pane fade data-grid linechart-card-size"
                          id="barChart"
                          role="tabpanel"
                          aria-labelledby="bar-chart"
                        >
                          <BarChart key={refreshToken} data={barChartAPIData} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 d-flex flex-column gap-3">
              <div className="card ab-chart-card flex-fill">
                <div className="card-header">
                  <h6>Profit Overview</h6>
                </div>
                <div className="card-body donut-chart-section-consumer d-flex align-items-center justify-content-center">
                  {isLoading.dount || Number(inputValue.gain) === 0 ? (
                    <Loader />
                  ) : (
                    <DoughnutChart data={chartData} />
                  )}
                </div>
              </div>

              <div className="card ab-chart-card flex-fill">
                <div className="card-header">
                  <h6>Recommendation Details</h6>
                </div>
                <div className="card-body ab-table p-0">
                  <div className="consumer-grid-table">
                    <div
                      className="ag-theme-alpin"
                      style={{ minHeight: "35vh" }}
                    >
                      <AgGridReact
                        ref={gridRef}
                        defaultColDef={{
                          flex: 2,
                          wrapText: true,
                          wrapHeaderText: true,
                          autoHeight: true,
                          resizable: false,
                        }}
                        groupHeaderHeight={45}
                        rowHeight={45}
                        rowData={selectedStock}
                        columnDefs={columnDefs}
                        // onRowClicked={(event: RowClickedEvent<stockSummary, any>) =>
                        //   handleOnRowClick(event.data!.id)
                        // }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={lineChartModal}
        className="modal-xl ab-modal-cs"
        backdrop="static"
      >
        <Modal.Header closeButton onClick={() => setLineChartModal(false)}>
          <Modal.Title>
            {selectedStock && (
              <>
                <Image
                  src={`https://firebasestorage.googleapis.com/v0/b/abillion-analytics.appspot.com/o/${selectedStock[0]?.stockSymbol}.jpg?alt=media`}
                  alt={selectedStock[0].stockName}
                  width={180}
                  height={180}
                  style={{ height: "40px", width: "auto" }}
                />{" "}
                {selectedStock[0].stockName}
              </>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="linechart-card-size-modal"
            style={{ minHeight: "78vh" }}
          >
            <LineChart data={investmentOverTime} />
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default React.memo(Consumer);
